<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            التعاميم
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            العنوان
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            المرسل لهم
                        </th>
                        <th>
                            من قام بالرد
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="m in mails" :key="m._id">
                            <td>
                                {{ m.title }}
                            </td>
                            <td>
                                {{ m.date }}
                            </td>
                            <td>
                                {{ m.selected.length }}
                            </td>
                            <td>
                                {{ Object.keys(m.replies).length - 1 }}
                            </td>
                            <td>
                                <button style="border-radius: 0px" v-b-modal.d @click="mail = m" class="btn btn-sm btn-success" title="عرض"><i class="fa fa-eye"></i> التفاصيل</button>
                                <button style="border-radius: 0px" @click="deleteMail(m._id)" class="btn btn-sm btn-danger" title="حذف"><i class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="d" title="التفاصيل" size="lg" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>المعلم</th>
                    <th>
                        الرد
                    </th>
                </thead>
                <tbody>
                    <tr v-for="t in mail.selected" :key="t">
                        <td>
                            {{ name(t) }}
                        </td>
                        <td>
                            <span v-if="!mail.replies[t]" class="text-muted"><i>لم يتم الرد</i></span>
                            <span v-if="mail.replies[t]">{{ mail.replies[t] }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            mail: {},
            teachers: [],
            user: JSON.parse(localStorage.getItem('user')),
            mails: [],
            teachers: []
        }
    },
    created(){
      if(!checkPer("teachers|mails")){
          this.$router.push('/NotPermitted')
          return false;
      }
        var g = this;
        g.getMails();
        $.post(api + "/user/teachers/list", {
            jwt: g.user.jwt,
        })
        .then(function (r) {
            g.teachers = r.response
        })
    },
    methods: {
        getMails(){
            var g = this;
            $.post(api + '/user/teachers/mails/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.mails = r.response
            })
        },
        deleteMail(id){
            var g = this;
            if(confirm("متأكد من الحذف؟")){
                $.post(api + '/user/teachers/mails/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.getMails()
                })
            }
        },
        name(n){
            var vv = "";
            this.teachers.forEach(function(a){
                if(a.number == n){
                    vv = a.name;
                }
            })
            return vv;
        }
    }
}
</script>

<style>

</style>